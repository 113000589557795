/* @import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap"); */
@font-face {
  font-family: BYekan;
  src: url('Fonts/BYekan.ttf');
}
@font-face {
  font-family: Nunito;
  src: url('Fonts/static/Nunito-Black.ttf');
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Nunito", sans-serif; */
}
body{
  direction: ltr;
}


/* body{
  background: linear-gradient(#070707, #02023f);
} */
.navbar {
  background: #fff;
}

.navbar-brand {
  font-size: 1.9rem;
  color: #565387;
}

.nav-item {
  font-size: 1.3rem;
  text-transform: capitalize;
}

.menu_active {
  font-weight: bold;
  border-bottom: 1px solid #565387;
}

.navbar a:hover,
#header .brand-name {
  color: #3498db;
}

#header {
  width: 100%;
  /* height: 80vh; */
  padding-top: 110px;
  padding-bottom: 110px;
}



#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
  /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */
}

#header h2 {
  color: #484848;
  font-size: 24px;
  /* font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif; */

}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #3498db;
  border: 2px solid #3498db;
  text-decoration: none;
}

#header .btn-get-started:hover {
  background: #3498db;
  color: #fff;
}

.header-img {
  text-align: right;
}

#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}


.persianStyle{
  direction:rtl;
  font-family:BYekan
}

.nonPersianStyle{
  direction:ltr;
  font-family:Nunito
}

p{
  text-align: justify;
  text-justify: inter-word;
}

#ads:hover{
  width: 40%;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #ads:hover{
    width: 100%;
    cursor: pointer;
  }
}